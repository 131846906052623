import React, { useState, useEffect } from "react";
import "../css/layout.css";
import "../css/homepage.css";
import css from "../css/with.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "../css/Loader.css";
import findGif from "../css/loading.gif";
import BankDetails from "./Bank_details";

const Withdrawopt = ({
  walletUpdate,
  isWithdrawEnabled,
  withdrawChargeTill1000,
}) => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const [Id, setId] = useState(null);
  const [user, setUser] = useState();
  const [holderName, setHolder_name] = useState();
  const [accountNumber, setAccount_number] = useState();
  const [ifscCode, setIfsc_code] = useState();
  const [email, setEmail] = useState();
  const [upiId, setUpi_id] = useState();
  const [next, setNext] = useState(false);

  const [isCashFreePayoutActive, setCashFreePayoutActive] = useState(false);
  const [isRazorPayPayoutActive, setRazorPayPayoutActive] = useState(false);
  const [isDecentroPayoutActive, setDecentroPayoutActive] = useState(false);
  const [isManualPayoutActive, setManualPayoutActive] = useState(false);

  const [isRazorPayPayoutAuto, setRazorPayPayoutAuto] = useState(false);
  const [isDecentroPayoutAuto, setDecentroPayoutAuto] = useState(false);
  const [maxAutopayAmt, setMaxAutopayAmt] = useState(0);

  const [submitBtn, setSubmitBtn] = useState(true);
  // new added for minimum withdraw limit
  const [minimumWithdrawLimit, setMinimumWithdrawLimit] = useState(99);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    // add new to get website setting for minimum withdraw
    axios
      .get(baseUrl + "settings/data")
      .then((response) => {
        let mini_withdraw = +response.data.minimumWithdrawLimit;
        setMinimumWithdrawLimit(mini_withdraw);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
    // end
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        setId(res.data._id);
        setHolder_name(res.data.holderName);
        setAccount_number(res.data.accountNumber);
        setIfsc_code(res.data.ifscCode);
        setUpi_id(res.data.upiId);
        setEmail(res.data.email);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });

    axios
      .get(baseUrl + `website/setting`)
      .then((res) => {
        //console.log(res);
        setCashFreePayoutActive(res.data.isCashFreePayoutActive);
        setRazorPayPayoutActive(res.data.isRazorPayPayoutActive);
        setDecentroPayoutActive(res.data.isDecentroPayoutActive);
        setManualPayoutActive(res.data.isManualPayoutActive);

        setRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto);
        setDecentroPayoutAuto(res.data.isDecentroPayoutAuto);
        setMaxAutopayAmt(res.data.maxAutopayAmt);
      })
      .catch((e) => {
        setManualPayoutActive(false);
        setCashFreePayoutActive(false);
        setRazorPayPayoutActive(false);
        setDecentroPayoutActive(false);
        setMaxAutopayAmt(0);
      });
  }, []);

  const updateBankDetails = async () => {
    setMount(true);
    setSubmitBtn(false);
    // e.preventDefault();
    let confirm = false;
    if (type == "upi" || type == "manualupi") {
      let regex = /^[\w.-]+@[\w.-]+$/.test(upiId);
      if (regex == true) {
        Swal.fire({
          title: `Is your UPI ID  is correct ? ` + upiId,
          icon: "success",
          confirmButtonText: "OK",
        });
        confirm = true;
        console.log("upi valid");
      } else {
        Swal.fire({
          title: "Invalid UPI ID: " + upiId,
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
        setSubmitBtn(true);
        console.log("upi invalid");
      }
    } else if (type === "bank") {
      // let nameRegex = /^(?=.{3,120}$)[a-zA-Z0-9 ',-_\/(),.]+(?<![\W])$/;
      let ifscRegex = /^[a-zA-Z0-9]{11}$/;
      let accountNumberRegex = /^[a-zA-Z0-9]{5,35}$/;
      if (!holderName || !accountNumber || !ifscCode) {
        Swal.fire({
          title: "Invalid bank details",
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
      } else if (!ifscRegex.test(ifscCode)) {
        Swal.fire({
          title: "Invalid IFSC code",
          text: "IFSC code has to be 11 characters in length and should not contain any special characters",
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
      } else if (!accountNumberRegex.test(accountNumber)) {
        Swal.fire({
          title: "Invalid account number",
          text: "Account number should be between 5-35 characters in length and should only contains alphanumeric characrers.",
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
      } else if (user.withdrawAmount < 1) {
        Swal.fire({
          title: "Insufficient balance",
          text: `${user.withdrawAmount}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
        setMount(false);
        setSubmitBtn(true);
        return;
      } else if (!amount || amount < minimumWithdrawLimit) {
        Swal.fire({
          title: `The minimum withdrawal amount is ${minimumWithdrawLimit}.`,
          text: `Amount should be minimum ${minimumWithdrawLimit}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        confirm = false;
        setMount(false);
        setSubmitBtn(true);
        return;
      } else {
        confirm = true;
      }
    } else {
      confirm = true;
      // if (!holderName || !accountNumber || !ifscCode) {
      //   setMount(false);
      //   setSubmitBtn(true);
      //   Swal.fire({
      //     title: "Invalid Bank Details",
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      //   confirm = false;
      //   console.log(" invalid bank details")
      // } else {
      //   confirm = true;
      // }
      //var confirmMsg = `Is your Bank Account Number is correct ? `+accountNumber;
    }

    if (confirm) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const data = await axios
        .patch(
          baseUrl + `user/edit`,
          {
            holderName,
            accountNumber,
            ifscCode,
            upiId,
            bankDetails: true,
            email,
          },
          { headers }
        )
        .then((res) => {
          console.log("updata bank details", res);
          if (res.data.subCode === "200") {
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referralEarning +
              user.holdBalance +
              user.totalBonus -
              (user.totalWithdrawl + user.referralWallet + user.totalPenalty);
            calculatedWallet == user.walletBalance
              ? doAutoPayout()
              : withReqComes();
          } else {
            setMount(false);
            setSubmitBtn(true);
            Swal.fire({
              title: res.data.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            history.push("/login");
          }
        });
    } else {
      setMount(false);
      setSubmitBtn(true);
    }
  };

  const [amount, setAmount] = useState();
  const [type, setType] = useState("bank");
  const [mount, setMount] = useState(false);
  // const [accunt, setUpi] = useState()
  // const [account_no, setAccount_no] = useState()
  // const [IFSC, setIFSC] = useState();

  //this function for handleAuto payout service with payment gateway

  const doAutoPayout = () => {
    if (isRazorPayPayoutAuto && type == "upi") {
      //alert('payoutFromRazorpay');
      if (amount <= maxAutopayAmt) {
        // payoutFromRazorpay();
        withReqComes();
      } else {
        // payoutFromRazorpay();
        withReqComes();
      }
    } else if (isDecentroPayoutAuto && type == "banktransfer") {
      //alert('payoutFromDecentro');
      if (amount <= maxAutopayAmt) {
        payoutFromDecentro();
      } else {
        withReqComes();
      }
    } else if (isManualPayoutActive && type == "manualupi") {
      //alert('payoutFromDecentro');
      if (amount <= maxAutopayAmt) {
        payoutFromManual();
      } else {
        withReqComes();
      }
    } else {
      //alert('withReqComes');
      withReqComes();
    }
  };

  //use for Razorpay payout

  const payoutFromManual = () => {
    if (amount && amount >= 200 && amount <= 10000 && type) {
      // e.preventDefault();
      const paymentGateway = "manualupi";
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `withdraw/payoutmanualupi`,
          {
            amount,
            type,
            paymentGateway,
          },
          { headers }
        )
        .then((response) => {
          walletUpdate();
          setMount(false);
          console.log(response.data);
          if (response.data.status === "Processing") {
            setTimeout(() => {
              axios
                .get(baseUrl + `manual/payoutstatus/${response.data._id}`, {
                  headers,
                })
                .then((res) => {
                  console.log(res);
                  const icon =
                    res.data.status === "SUCCESS" ? "success" : "danger";
                  var title = "";
                  if (res.data && res.data.status === "SUCCESS") {
                    title = "Withdraw successfully done";
                  } else if (res.data && res.data.status === "Processing") {
                    title = "Withdrawal transaction in proccess.";
                  } else if (!res.data.status) {
                    title = "Withdraw request transaction Rejected";
                  }

                  history.push("/");
                  setTimeout(() => {
                    setMount(false);
                    Swal.fire({
                      title: title,
                      icon: icon,
                      confirmButtonText: "OK",
                    });
                  }, 1000);
                });
            }, 30000);
            setMount(true);
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "danger",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          setMount(false);
          Swal.fire({
            title: "Error! try after sometime.",
            icon: "error",
            confirmButtonText: "OK",
          });
          console.log(e);
        });
    } else {
      setMount(false);
      let msg = "Enter all fields";
      if (!amount || !type) {
        msg = "Enter all fields";
      } else if (200 <= amount <= 10000) {
        msg = "amount should be more than 200 and less then 10000.";
      }
      Swal.fire({
        title: msg,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  const payoutFromRazorpay = () => {
    if (amount && amount >= 200 && amount <= 50000 && type) {
      // e.preventDefault();
      const paymentGateway = "razorpay";
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `withdraw/payoutrazorpaybank`,
          {
            amount,
            type,
            paymentGateway,
          },
          { headers }
        )
        .then((res) => {
          walletUpdate();
          setMount(false);
          console.log(res.data);
          if (res.data.subCode === "200") {
            console.log("cash res", res);
            Swal.fire({
              title: res.data.message,
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: res.data.message,
              icon: "danger",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          setMount(false);
          Swal.fire({
            title: "Error! try after sometime.",
            icon: "error",
            confirmButtonText: "OK",
          });
          console.log(e);
        });
    } else {
      setMount(false);
      let msg = "Enter all fields";
      if (!amount || !type) {
        let msg = "Enter all fields";
      } else if (200 <= amount <= 50000) {
        msg = "amount should be more than 200 and less then 50000.";
      }
      Swal.fire({
        title: msg,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  //use for Razorpay payout end

  //use for decentro payout

  const payoutFromDecentro = () => {
    if (amount && amount >= minimumWithdrawLimit && amount <= 50000 && type) {
      // e.preventDefault();
      const paymentGateway = "decentro";
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `withdraw/payoutdecentrobank`,
          {
            amount,
            type,
            paymentGateway,
          },
          { headers }
        )
        .then((res) => {
          setTimeout(() => {
            walletUpdate();
          }, 5000);
          setMount(false);
          console.log(res.data);
          if (res.data.subCode === "200") {
            console.log("cash res", res);
            Swal.fire({
              title: res.data.message,
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: res.data.message,
              icon: "danger",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          setMount(false);
          Swal.fire({
            title: "Error! try after sometime.",
            icon: "error",
            confirmButtonText: "OK",
          });
          console.log(e);
        });
    } else {
      setMount(false);
      let msg = "Enter all fields";
      if (!amount || !type) {
        let msg = "Enter all fields";
      } else if (minimumWithdrawLimit <= amount <= 50000) {
        msg = `amount should be more than ${minimumWithdrawLimit} and less then 50000.`;
      }
      Swal.fire({
        title: msg,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  //use for decentro payout end

  const handleSubmitdata = () => {
    if (amount && amount >= minimumWithdrawLimit && amount <= 20000 && type) {
      // e.preventDefault();
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          baseUrl + `withdraw/bank`,
          {
            amount,
            type,
          },
          { headers }
        )
        .then((res) => {
          setTimeout(() => {
            walletUpdate();
          }, 5000);
          setMount(false);
          console.log(res.data);
          if (res.data.subCode === "200") {
            console.log("cash res", res);
            Swal.fire({
              title: res.data.message,
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: res.data.message,
              icon: "danger",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          setMount(false);
          Swal.fire({
            title: "Error! try after sometime.",
            icon: "error",
            confirmButtonText: "OK",
          });
          console.log(e);
        });
    } else {
      setMount(false);
      let msg = "Enter all fields";
      if (!amount || !type) {
        let msg = "Enter all fields";
      } else if (minimumWithdrawLimit <= amount <= 20000) {
        msg = `amount should be more than ${minimumWithdrawLimit} and less then 100000.`;
      }
      Swal.fire({
        title: msg,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  const withReqComes = async () => {
    try {
      // if(!amount == 0 || amount < 200 || amount == 0) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Please enter amount mim 200",
      //     confirmButtonText: "OK",
      //   }).then(()=>{
      //     return
      //   })
      // }
      setMount(true);

      if (type == "upi") {
        var paymentGateway = "manualupi";
      } else if (type == "manualupi") {
        var paymentGateway = "manualupi";
      } else {
        var paymentGateway = "decentro";
      }

      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      await axios
        .post(
          baseUrl + `withdraw/request`,
          {
            amount,
            type,
            paymentGateway,
          },
          { headers }
        )
        .then((res) => {
          walletUpdate();
          if (res.data.success) {
            Swal.fire({
              title: res.data.msg,
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
          setMount(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // const [upiId, setUpiId] = useState("");
  const [reEnterUpiId, setReEnterUpiId] = useState("");
  const [isUpiIdMatched, setIsUpiIdMatched] = useState(true);

  const handleUpiIdChange = (e) => {
    setUpi_id(e.target.value);
    // Reset the match status when the UPI ID changes
    setIsUpiIdMatched(true);
  };

  const handleReEnterUpiIdChange = (e) => {
    setReEnterUpiId(e.target.value);
    // Check if the entered UPI ID matches the original one
    setIsUpiIdMatched(e.target.value === upiId);
  };

  if (isWithdrawEnabled) {
    return (
      <>

        {(user?.verified === "unverified" || user?.bankaccount_status !== "VALID") ? (
          <div className="alert-container mx-5" style={{ marginTop: "145px" }}>
            {user?.verified === "unverified" && user?.bankaccount_status !== "VALID" ? (
              <div className="kyc-verification">
                <p>Please complete your KYC verification first to take withdraw</p>
                <Link to="/Kyc2">
                  <button className="btn btn-warning">Verify KYC</button>
                </Link>
              </div>
            ) : (
              user?.bankaccount_status !== "VALID" && (
                <div className="kyc-verification">
                  <p>Please verify your bank details to withdraw funds.</p>
                  <Link to="/bankverify">
                    <button className="btn btn-warning">Verify Bank</button>
                  </Link>
                </div>
              )
            )}
          </div>

        ) : (
          <div
            className="leftContainer"
            style={{ minHeight: "100vh", height: "100%" }}
          >
            {Boolean(mount) && (
              <div
                className="loaderReturn"
                style={{ minHeight: "100vh", height: "100%" }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/Images/LandingPage_img/loader1.gif"
                  }
                  style={{ width: "100%", height: "486px" }}
                />
              </div>
            )}
            <div className="px-3  py-5" style={{ height: "10px" }}>
              <div className="row">
                <div className="col mx-auto">
                  <div className="card text-center mt-4 mb-5">
                    {user && user.verified === "verified" && (
                      <div className="card-body " style={{ padding: "15px" }}>
                        {/* <h4 className="pt-1 text-left">
                      <b>{type == undefined ? 'Choose withdrawal option.' : 'Withdraw through'}</b>
                    </h4>
                    <h5 className="pt-1 text-center text-danger">
                      <b>FAST withdrawal Using PayTm Upi</b>
                    </h5> */}

                        <div>
                          <div className="row bg-light p-1">
                            <div
                              className="text-left w-100"
                              style={{ background: "white" }}
                            >
                              {/* <div>
                                <label htmlFor="username " className="mr-5">
                                  <i className="far fa-bank mr-2"></i>UPI ID
                                </label>
                                <div className="col-12  p-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="account_no"
                                    placeholder="Enter UPI ID "
                                    name="ifsc"
                                   
                                  />
                                </div>
  
                                <label htmlFor="username " className="mr-5">
                                  <i className="far fa-bank mr-2"></i> Re Enter UPI ID
                                </label>
                                <div className="col-12  p-0">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="account_no"
                                    placeholder=" Re Enter UPI ID "
                                    name="ifsc"
                                    value={upiId}
                                    onChange={(e) => setUpi_id(e.target.value)}
                                  />
                                </div>
                              </div> */}

                              {/* new withdrawal upi  */}
                              <div
                                className=" col-12 mx-auto g-0  iframe-sec"
                                style={{ paddingRight: "0", paddingLeft: "0" }}
                              >
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <div>
                                    <Link to={"/wallet"}>
                                      <button
                                        className="d-flex justify-content-between"
                                        style={{
                                          alignItems: "center",
                                          backgroundColor: "#4782F4",
                                          padding: "8px",
                                          paddingInline: "20px",
                                          border: "none",
                                          borderRadius: "5px",
                                          color: "#fff",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 16 16"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          class="me-2"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                          ></path>
                                        </svg>
                                        <span
                                          class="text-capitalize"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Back
                                        </span>
                                      </button>
                                    </Link>
                                  </div>
                                  <div>
                                    <div
                                      className={`${css.menu_items}`}
                                      style={{ position: "relative" }}
                                    >
                                      <button
                                        className="guide-btn d-flex "
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasBottom"
                                        aria-controls="offcanvasBottom"
                                        style={{ alignItems: "center" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 16 16"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          class="me-2"
                                        >
                                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                                        </svg>
                                        Guide
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mb-3 card"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <div
                                    className="bg-light text-dark card-header text-center "
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Payment Mode
                                  </div>
                                  <div
                                    className=" text-center py-1"
                                    style={{ backgroundColor: "#6C757D" }}
                                  >
                                    <span
                                      className="text-white  "
                                      style={{
                                        fontSize: "0.9rem",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      Withdrawal Chips:{" "}
                                      {user && user.withdrawAmount}
                                    </span>
                                  </div>
                                  <div className="card-body">
                                    <div className="d-flex flex-row align-items-center justify-content-between mb-1">
                                      <span
                                        style={{
                                          fontSize: "0.9rem",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Minimum: {minimumWithdrawLimit}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: "0.9rem",
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        Maximum: 1,00,000
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mb-3 shadow card"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <div
                                    className="bg-light text-dark card-header text-center "
                                    style={{
                                      fontFamily: "Poppins",
                                      backgroundColor: "#F8F9FA",
                                    }}
                                  >
                                    Payment Details
                                  </div>
                                  <div className="card-body">
                                    <form className="">
                                      <div className="vstack gap-3">
                                        <div>
                                          <div>
                                            <label
                                              htmlFor="username "
                                              className="mr-5"
                                            >
                                              <i className="far fa-bank mr-2"></i>
                                              Account holder name
                                            </label>
                                            <div className="col-12 mb-3 p-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account_no"
                                                placeholder="Enter Account Name"
                                                name="ifsc"
                                                value={holderName}
                                                onChange={(e) =>
                                                  setHolder_name(e.target.value)
                                                }
                                                required
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <label
                                              htmlFor="username "
                                              className="mr-5"
                                            >
                                              <i className="far fa-bank mr-2"></i>
                                              Account number
                                            </label>
                                            <div className="col-12 mb-3 p-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account_no"
                                                placeholder="Enter your bank account number"
                                                name="upi"
                                                value={accountNumber}
                                                onChange={(e) =>
                                                  setAccount_number(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <label
                                              htmlFor="username "
                                              className="mr-5"
                                            >
                                              <i className="far fa-bank mr-2"></i>
                                              IFSC code
                                            </label>
                                            <div className="col-12 mb-3 p-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account_no"
                                                placeholder="Enter IFSC code"
                                                name="ifsc"
                                                value={ifscCode}
                                                onChange={(e) =>
                                                  setIfsc_code(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            <label
                                              htmlFor="username "
                                              className="mr-5"
                                            >
                                              <i className="far fa-bank mr-2"></i>
                                              Email
                                            </label>
                                            <div className="col-12 mb-3 p-0">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="account_no"
                                                placeholder="Enter email address"
                                                name="email"
                                                required
                                                value={email}
                                                onChange={(e) =>
                                                  setEmail(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div>
                                        <div>
                                          <label
                                            className="text-capitalize text-start w-100 form-label "
                                            style={{
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                            }}
                                            htmlFor="upiFormAccountNumber"
                                          >
                                            UPI ID
                                          </label>
                                          <input
                                            placeholder="Your UPI ID"
                                            type={
                                              isUpiIdMatched
                                                ? "text"
                                                : "password"
                                            }
                                            className="form-control form-control "
                                            style={{
                                              borderRadius: "6px",
                                              border: " 1px solid #ccc",
                                            }}
                                            defaultValue=""
                                            id="account_no"
                                            name="upiId"
                                            value={upiId}
                                            onChange={handleUpiIdChange}
                                          />
                                        </div>
                                        <div>
                                          <label
                                            className="text-capitalize text-start w-100 form-label pt-3"
                                            style={{
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                            }}
                                            htmlFor="upiFormReEnterAccountNumber"
                                          >
                                            Re Enter UPI ID
                                          </label>
                                          <input
                                            placeholder="Re Enter Your UPI ID"
                                            type={
                                              isUpiIdMatched ? "text" : "text"
                                            }
                                            className="form-control form-control"
                                            style={{
                                              borderRadius: "6px",
                                              border: " 1px solid #ccc",
                                            }}
                                            defaultValue=""
                                            id="account_no"
                                            name="reEnterUpiId"
                                            value={reEnterUpiId}
                                            onChange={handleReEnterUpiIdChange}
                                          />
                                          {!isUpiIdMatched && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontFamily: "Poppins",
                                              }}
                                            >
                                              UPI IDs do not match. Please
                                              re-enter.
                                            </p>
                                          )}
                                        </div>
                                      </div> */}
                                        <div>
                                          <label
                                            className="text-capitalize text-start w-100 form-label"
                                            htmlFor="upiFormChips"
                                            style={{
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                            }}
                                          >
                                            chips
                                          </label>
                                          <input
                                            placeholder="Chips"
                                            min={200}
                                            max={10000}
                                            type="number"
                                            id="upiFormChips"
                                            className="form-control form-control"
                                            style={{
                                              borderRadius: "6px",
                                              border: " 1px solid #ccc",
                                            }}
                                            defaultValue=""
                                            onChange={(e) =>
                                              setAmount(e.target.value)
                                            }
                                          />
                                          {amount <= 1000 && amount >= 1 && (
                                            <div className="text-danger mt-2">
                                              <strong>Note:</strong> A fee of ₹
                                              {withdrawChargeTill1000} will be
                                              applied to withdrawal requests below
                                              ₹1000.
                                            </div>
                                          )}
                                        </div>
                                        <p
                                          style={{
                                            fontSize: "0.9rem",
                                            fontFamily: "Poppins",
                                            textAlign: "center",
                                          }}
                                        >
                                          By Continuing, you agree to our{" "}
                                          <a
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                            href="/term-condition"
                                          >
                                            Legal Terms
                                          </a>{" "}
                                          and you are 18 years or older.
                                        </p>
                                        <div className="col-12 p-0 mt-2 pt-3">
                                          <button
                                            type="button"
                                            className=" btn-block btn-sm border-0 "
                                            style={{
                                              height: "40px",
                                              backgroundColor: "#0B5ED7",
                                              color: "white",
                                              borderRadius: "8px",
                                            }}
                                            disabled={
                                              Boolean(submitBtn) ? false : true
                                            }
                                            onClick={() => updateBankDetails()}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div style={{ marginBottom: 15 }}>
                                  <ul className="list-group" />
                                </div>
                              </div>

                              {/* {Boolean(isManualPayoutActive) && Boolean(type == "manualupi") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid grey",
                                borderRadius: "7px",
                              }}
                            >
                            
                            
                              <div
                                className="d-flex align-items-center"
                                style={{ height: "60px", display: "flex", textAlign: "center" }}
                              >
                                <img
                                  width="45px"
                                  src={process.env.PUBLIC_URL + '/UPI.png'}
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="text-left">
                                  <strong>Withdraw through UPI</strong>
                                </div>
                                <div className="jss31"></div>
                              </div>
                              <button onClick={() => { setNext(false) }} className="btn btn-info text-white font-weight-bold  ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                            </div>
                          </div>} */}

                              {/* {Boolean(isRazorPayPayoutActive) && Boolean(type == "upi") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid grey",
                                borderRadius: "7px",
                              }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{ height: "60px", display: "flex", textAlign: "center" }}
                              >
                                <img
                                  width="45px"
                                  src={process.env.PUBLIC_URL + '/UPI.png'}
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="text-left">
                                  <strong>Withdraw through UPI</strong>
                                  <br />
                                  <small className="text-warning">Minimum withdrawal amount ₹95</small>
                                   <br/>
                              <small className="text-danger">Instant withdrawal within 5 Mint.</small>
                                </div>
                                <div className="jss31"></div>
                              </div>
                              <button onClick={() => { setNext(false) }} className="btn btn-info text-white font-weight-bold  ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                            </div>
                          </div>} */}

                              {/* { Boolean(isDecentroPayoutActive) && Boolean(type == "banktransfer") && <div className="add-fund-box my-3" style={{ paddingTop: "0px", height: "60px" }}>
                            <div
                              className="d-flex align-items-center"
                              style={{ backgroundColor: "#fafafa", border: "1px solid grey", borderRadius: "7px" }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{ height: "60px", display: "flex", textAlign: "center" }}
                              >
                                <img
                                  width="45px"
                                  src={process.env.PUBLIC_URL + '/Bank.png'}
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              
                              
                              
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="text-left">
                                  <strong>Bank Transfer</strong>
                                  <br />
                                  <small className="text-warning">Minimum withdrawal amount ₹95</small>
                                  <br/>
                                  <small className="text-danger">Direct Bank Transaction will take 1-2 hour</small>
                                </div>
                                <div className="jss31"></div>
                              </div>
                              <button onClick={() => { setType(undefined); setNext(false); console.log(type) }} className="btn btn-info text-white font-weight-bold ml-auto mr-3" style={{ fontSize: '0.5rem' }}>Edit</button>
                            </div>
                          </div>} */}
                              <div className="">
                                {/* {Boolean(isDecentroPayoutActive) &&
                                type == "banktransfer" && ( */}
                                {/* )} */}

                                {/* {Boolean(isManualPayoutActive) && type == "manualupi" && <div >
                            
                            <div >
                              <label htmlFor="username " className="mr-5">
                                <i className="far fa-bank mr-2"></i>Account holder name
                              </label>
                              <div className="col-12 mb-3 p-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  placeholder="Enter Account Name"
                                  name="ifsc"
                                  value={holderName}
                                  onChange={(e) => setHolder_name(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            
                              <label htmlFor="username " className="mr-5">
                                <i className="far fa-bank mr-2"></i>UPI ID
                              </label>
                              <div className="col-12  p-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  placeholder="Enter UPI ID (9999999999@xyz)"
                                  name="ifsc"
                                  value={upiId}
                                  onChange={(e) => setUpi_id(e.target.value)}
                                />
                              </div>
                              
                              <small className="text-danger">
                              कृपया सही UPI आईडी (9999999999@xyz) दर्ज करें।*
                              </small>
                            </div>} */}
                                {/* 
                                {Boolean(isRazorPayPayoutActive) && type == "upi" && 
                            <div >
                            
                            <div >
                              <label htmlFor="username " className="mr-5">
                                <i className="far fa-bank mr-2"></i>Account holder name
                              </label>
                              <div className="col-12 mb-3 p-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  placeholder="Enter Account Name"
                                  name="ifsc"
                                  value={holderName}
                                  onChange={(e) => setHolder_name(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            
                              <label htmlFor="username " className="mr-5">
                                <i className="far fa-bank mr-2"></i>UPI ID
                              </label>
                              <div className="col-12  p-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account_no"
                                  placeholder="Enter UPI ID (9999999999@xyz)"
                                  name="ifsc"
                                  value={upiId}
                                  onChange={(e) => setUpi_id(e.target.value)}
                                />
                              </div>
                              
                              <small className="text-danger">
                              कृपया सही UPI आईडी (9999999999@xyz) दर्ज करें।*
                              </small>
                            </div>} */}
                                {/* <button type="submit" className="btn w-50 d-block m-auto text-white btn-primary">Submit</button> */}
                              </div>
                            </div>

                            {/* bank details end */}

                            {/* <div>
                              <label htmlFor="username " className="mr-5">
                                <i className="far fa-money mr-2"></i>Amount
                              </label>
                            </div>
                            <div className="field col-12 p-0 mt-1 mb-3">
                              <input
                                type="tel"
                                className="form-control search-slt"
                                name="amount"
                                placeholder="amount"
                                onChange={(e) => setAmount(e.target.value)}
                              />
                            </div>
                            <div className="col-12 p-0 mt-2 pt-3">
                              <button
                                type="button"
                                className=" btn-block btn-sm"
                                style={{
                                  height: "40px",
                                  backgroundColor: "rgb(15, 125, 44)",
                                  color: "white",
                                  borderRadius: "21px",
                                }}
                                disabled={Boolean(submitBtn) ? false : true}
                                onClick={() => updateBankDetails()}
                              >
                                Withdraw
                              </button>
                              {Boolean(submitBtn) ? "" : "Reload Page"}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    )}


                    {/* {user && user.verified === "unverified" && user.bankaccount_status !== "VALID" && (
                    // <Link to="/Kyc2">
                    <Link to="/profile">
                      <div>
                        <picture className="ml-3">
                          <img
                            src="/images/alert.svg"
                            alt=""
                            width="32px"
                            className="mt-4"
                          />
                        </picture>
                        <div
                          className="ml-1 mt-2 mytext text-muted"
                          style={{ fontFamily: "Poppins" }}
                        >
                          Verify Your KYC and Bank_details to take Withdrawals
                        </div>
                      </div>
                    </Link>
                  )} */}


                    {user && user.verified === "pending" && (
                      <div>
                        <picture className="ml-3">
                          <img
                            src="/images/alert.svg"
                            alt=""
                            width="32px"
                            className="mt-4"
                          />
                        </picture>
                        <div
                          className="ml-1 mt-2 mytext text-muted "
                          style={{ fontFamily: "Poppins" }}
                        >
                          Please wait your kyc under process
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <div
          className="leftContainer bg-white"
          style={{ minHeight: "100vh", height: "100%", paddingTop: "50px" }}
        >
          <div className="p-1">
            <div className="p-3">
              <div className="mb-3">
                <div
                  role="alert"
                  className="fade d-flex align-items-center justify-content-between alert alert-warning show"
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    fontWeight: "800",
                    marginTop: "40px",
                  }}
                >
                  <span>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "0.8rem",
                        textAlign: "unset",
                        fontWeight: "800",
                        color: "#664d03",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width={20}
                        height={20}
                        fill="red"
                      >
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>{" "}
                      &nbsp;&nbsp;असुविधा के लिए खेद है, कृपया सभी प्लेयर्स से
                      अनुरोध है की धैर्य बनाए रखे आपका अमाउंट सेफ है !
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default Withdrawopt;
